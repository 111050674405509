import { type Learner } from '@greenworkx/gwx-api/dist/spec/schemas';
import { useContent } from '@lib/swr/useContent';
import { useLearnerApi } from '@lib/swr/useLearnerApi';
import {
  advanceToNextPrompt,
  FEEDBACK_LOAD_DELAY_MS,
} from '@util/milestones/advance_to_next_prompt';
import { getUnseenPrompts } from '@util/milestones/get_unseen_prompts';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import {
  currentUserPromptIndexAtom,
  isFinalPromptDismissedAtom,
  isMilestoneUserPromptVisibleAtom,
} from 'store';

export function usePromptHandler() {
  const { learner, gwxApiIsLoading } = useLearnerApi();
  const { content, contentApiIsLoading } = useContent();

  const [isPromptVisible, setIsPromptVisible] = useAtom(isMilestoneUserPromptVisibleAtom);
  const [currentPromptIndex, setCurrentUserPromptIndex] = useAtom(currentUserPromptIndexAtom);
  const setIsFinalPromptDismissed = useSetAtom(isFinalPromptDismissedAtom);

  const learnerPartial: Partial<Learner> = {
    milestones: {
      completed: learner?.milestones?.completed,
      completedAndSeen: learner?.milestones?.completedAndSeen,
    },
  };

  const unseenPrompts = getUnseenPrompts(learnerPartial);

  const isLastPrompt = unseenPrompts && currentPromptIndex === unseenPrompts.length - 1;
  const isRemainingPrompt = unseenPrompts && unseenPrompts.length > currentPromptIndex + 1;

  useEffect(() => {
    if (content && learner) {
      setTimeout(() => {
        setIsPromptVisible(true);
      }, FEEDBACK_LOAD_DELAY_MS);
    }
  }, [contentApiIsLoading, gwxApiIsLoading, content, learner, setIsPromptVisible]);

  const onDismiss = () => {
    setIsPromptVisible(false);
    if (isRemainingPrompt) {
      advanceToNextPrompt({
        currentPromptIndex,
        setIsPromptVisible,
        setCurrentUserPromptIndex,
      });
    } else if (isLastPrompt) {
      setIsFinalPromptDismissed(true);
    }
  };

  return {
    onDismiss,
    isPromptVisible,
    currentPromptIndex,
    unseenPrompts,
  };
}
